import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  static targets = [
    'polsItemDetailContainer',
    'polsItemDetailAttributes',
  ];

  connect() {
    this.checkPolStatus();
    this.disableCheckboxCompleted()
  }

  disableCheckboxCompleted() {
    const $polsItemDetailAttributes = $(this.polsItemDetailAttributesTarget);
    const itemDetailCount = $polsItemDetailAttributes.find('div').length;
    if (itemDetailCount == 0) {
      const polStatus = document.getElementById('pol_status');
      polStatus?.setAttribute('disabled', 'true');
    }
  }

  initialize() {
    this.initialDataTables();
  }

  checkPolStatus() {
    const polStatus = document.getElementById('pol_status')
    if (polStatus) {
      if (polStatus.value == "complete") {
        document.getElementById('checkboxConfirmCompletedPol')?.setAttribute('disabled', 'true');
      }
    }
  }

  confirmCompletedPol(e) {
    this.myModal($('#confirmCompletedPol')).show();
  }

  cancelCompletedPol(e) {
    document.getElementById("checkboxConfirmCompletedPol").checked = false;
  }

  confirmCompleted() {
    document.getElementById('pol_status').value = 'complete'
    document.forms["formEditPol"].submit();
  }

  updatePolsItemDetailQty(e) {
    const itemDetailId = $('#editPolsItemDetailId').val();
    const qty = parseInt($('#editPolsItemDetailNeedQty').val());
    const pocQty = parseInt($('#editPolsItemDetailQty').val());
    const orderedQty = parseInt($('#editPolsItemDetailOrderedQty').val());
    const extraStockQty = (qty + orderedQty - pocQty) >= 0 ? (qty + orderedQty - pocQty) : 0;

    $(`#item_detail_${itemDetailId} input[name$='[qty]']`).val(qty);
    $(`#item-detail-${itemDetailId}-row td:eq(-2)`).html(qty);
    $(`#item_detail_${itemDetailId} #extra_stock_qty`).val(extraStockQty);
    $(`#item-detail-${itemDetailId}-row td:eq(-3)`).html(extraStockQty);
  }

  confirmDelete(e) {
    this.myModal($('#confirmDeleteModal')).show();
    const sku = $('#editPolsItemDetailSku').val();
    $('#confirmDeleteSku').text(`sku: ${sku}`);
  }

  cancelDelete(e) {
    const polId = $('#pol_id').val();
    const polsItemId = $('#editPolsItemDetailId').val();

    this.myModal($('#modalEditPolsItemDetail')).show();
    const url = `/pols/${polId}/edit_pols_item_detail/${polsItemId}`;
    get(url, {
      responseKind: 'turbo-steam',
    });
  }

  submitPolsItemDetail(e) {
    $('#form-pols-item-detail').submit();
  }

  submitAddMorePolsItemDetail(e) {
    const addMore = parseInt($('#editPolsItemDetailAddMoreQTY').val());
    let itemCount = parseInt($('#editPolsItemDetailNeedQty').val());
    const control = async () => {
      const itemDetailId = $('#pols_item_detail_item_detail_id').val();
      const polId = $('#pol_id').val();
      for (let i = 0; i < addMore; i += 1) {
        $('#ItemsAttributes').append(`
          <div id="polsItemDetail-${polId}-Item-new">
            <input type="hidden" value="${itemDetailId}" name="pols_item_detail[items_attributes][${itemCount}][item_detail_id]" id="pols_item_detail_items_attributes_${itemCount}_item_detail_id">
            <input type="hidden" value="false" name="pols_item_detail[items_attributes][${itemCount}][_destroy]">
          </div>`);
        itemCount += 1;
      }
    };
    if (addMore > 0) {
      control();
    }
    $("input[type=checkbox][name='item']").each((index, item) => {
      $(`#item-${item.value}`).val('false');
    });
    $('#form-pols-item-detail').submit();
  }

  hidePolsItemDetailContainer() {
    const $polsItemDetailAttributes = $(this.polsItemDetailAttributesTarget);
    const itemDetailCount = $polsItemDetailAttributes.find('div').length;

    if (itemDetailCount === 0) {
      this.polsItemDetailContainerTarget.classList.add('d-none');
    }
  }

  setPolsItemDetailForm(e) {
    const $elem = $(e.target);
    const data = $elem.data('item-detail');
    const qty = $(`#item_detail_${data.id} input[name$='[qty]']`).val();
    const extraStockQty = $(`#item_detail_${data.id} #extra_stock_qty`).val();
    const polId = $('#pol_id').val();

    this.myModal($('#modalEditPolsItemDetail')).show();

    const url = `/pols/${polId}/edit_pols_item_detail/${data.pols_item_detail_id}`;
    get(url, {
      responseKind: 'turbo-steam',
    });

    $('#editPolsItemDetailPolsItemDetailId').val(data.pols_item_detail_id);
    $('#editPolsItemDetailId').val(data.id);
    $('#editPolsItemDetailPoc').val(data.order_id);
    $('#editPolsItemDetailSku').val(data.sku);
    $('#editPolsItemDetailProduct').val(data.style_vendor);
    $('#editPolsItemDetailColor').val(data.color);
    $('#editPolsItemDetailSize').val(data.size);
    $('#editPolsItemDetailCategory').val(data.category);
    $('#editPolsItemDetailQty').val(data.qty);
    $('#editPolsItemDetailOrderedQty').val(data.ordered_qty);
    $('#editPolsItemDetailReceivedQty').val(data.received_qty);
    $('#editPolsItemDetailStockQty').val(data.stock_qty);
    $('#editPolsItemDetailExtraStockQty').val(extraStockQty);
    $('#editPolsItemDetailNeedQty').val(qty);
    $("button[id^='editModalCancel']").attr(
      'id',
      `editModalCancel${data.pols_item_detail_id}`,
    );
    $("button[id^='editModelDelete']").attr(
      'id',
      `editModelDelete${data.pols_item_detail_id}`,
    );
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }

  initialDataTables() {
    const table = $('table#polsItemDetailTable');

    if (!table.length) { return; }

    table.dataTable({
      columnDefs: [
        { orderable: false, targets: 'nosort' },
      ],
      order: [],
    });

    document.addEventListener('turbo:before-cache', () => {
      const table = $('table#polsItemDetailTable');

      if (!table.length) { return; }

      const tableConstant = table.DataTable();
      tableConstant.destroy();
    });
  }
}
