import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['pocItemDetailContainer'];

  initialize() {
    this.initialSelect2();
    this.initialDataTables();
  }

  pocChange(e) {
    const selectPocIds = e.target.selectedOptions;
    const polId = $('#pol_id').val();
    const pocIds = Array.from(selectPocIds, (option) => option.value);

    const url = `/pols/${polId}/pols_item_details/pocs_item_details?poc_ids=${pocIds}`;
    this.openSpanner();
    get(url, {
      responseKind: 'turbo-steam',
    })
      .then(() => {
        if (pocIds.length < 1) {
          this.hiddenPocItemDetail();
          this.disableSpanner();
        } else {
          this.pocItemDetailContainerTarget.classList.remove('d-none');
          this.disableSpanner();
        }
      })
      .catch(() => {
        this.hiddenPocItemDetail();
      });
  }

  openSpanner(e) {
    $('.overlay').addClass('show');
    $('.spanner').addClass('show');
  }

  disableSpanner(e) {
    $('.spanner').removeClass('show');
    $('.overlay').removeClass('show');
  }

  checkBoxSeleced(e) {
    $(`#item-detail-qty-${e.target.value}`).prop('checked', e.target.checked);
    if (e.target.checked) {
      $(`input[type=number][id='${e.target.value}'`)[0].disabled = false;
    } else {
      $(`input[type=number][id='${e.target.value}'`)[0].disabled = true;
    }
  }

  toggleSelectAllItemDetail(e) {
    $("input[type=checkbox][name='pols_item_detail[][item_detail_id]'], input[type=checkbox][name='pols_item_detail[][qty]']").prop(
      'checked',
      e.target.checked,
    );

    $('input[type=number][name="item-detail"').each((k, v) => {
      if (e.target.checked) {
        v.disabled = false;
      } else {
        v.disabled = true;
      }
    });
  }

  hiddenPocItemDetail() {
    this.pocItemDetailContainerTarget.classList.add('d-none');
  }

  onSelect2Change(e) {
    const select = e.currentTarget;
    select.dispatchEvent(new Event('change'));
  }

  initialSelect2() {
    const select = $('select#poc');

    if (!select.length) { return; }
    if ($(select).data('select2')) { return; }

    $(select)
      .select2()
      .on('select2:select select2:unselect', this.onSelect2Change);

    document.addEventListener('turbo:before-cache', () => {
      if (!$(select).data('select2')) { return; }

      $(select).select2('destroy');
    });
  }

  initialDataTables() {
    const table = 'table#pocItemDetailTable';

    if (!$(table).length) { return; }

    if (!$.fn.DataTable.isDataTable(table)) {
      $(table).DataTable({
        paging: false,
        columnDefs: [
          { orderable: false, targets: 0 },
        ],
        order: [],
      });
    }
  }

  setQTY(e) {
    const neet_qty = parseInt(e.target.value);
    const QTY = parseInt($(`input[type=hidden][name="needQTY-${e.target.id}"`).val());
    if (neet_qty <= QTY) {
      $(`#item-detail-qty-${e.target.id}`).val(neet_qty);
    }
  }
}
